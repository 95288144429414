<template>
  <div class="report">
    <van-nav-bar
      :title="this.$route.meta.title"
      left-arrow
      @click-left="onClickLeft"
    />
    <p class="bigTitle">反馈内容</p>
    <p class="title">投诉问题</p>
    <van-field
      readonly
      clickable
      :value="feedbackValue"
      placeholder="请选择投诉类型"
      @click="showPicker = true"
    />
    <p class="title"><span style="color: #EE394E">*</span>投诉/建议内容</p>
    <van-field
      class="text"
      v-model="content"
      rows="2"
      autosize
      type="textarea"
      maxlength="200"
      placeholder="请输入投诉的详细内容"
      show-word-limit
    />
    <p class="title">问题截图</p>
    <p class="title">
      <van-uploader :after-read="afterRead" v-model="fileList" :max-count="1"/>
    </p>
    <p class="bigTitle">登记联系方式，方便我们联系您</p>
    <p class="title"><span style="color: #EE394E">*</span>联系人</p>
    <van-field v-model="contact" placeholder="请输入联系人"/>
    <p class="title"><span style="color: #EE394E">*</span>手机号码</p>
    <van-field v-model="mobile" placeholder="请输入手机号" type="number" maxlength="11"/>
    <div class="report_btn_wrapper">
      <van-button type="info" class="report_btn" @click="submit" :disabled="mobile === null || mobile === ''">提交投诉/建议</van-button>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { uploadFileApi, postWebpageFeedbackSubmit } from '../../api/api'
import { Toast } from "vant";

export default {
  name: 'report',
  data () {
    return {
      content: null,
      mobile: null,
      contact: null,
      feedbackValue: null,
      feedbackType: null,
      imgUrl: null,
      fileList: [],
      columns: ['套餐资费问题', '客服态度问题'],
      showPicker: false,
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    afterRead(file) {
      console.log(file);
      let _formData = new FormData()
      _formData.append('file', file.file)
      Toast.loading({
        message: '加载中...',
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      uploadFileApi(_formData).then(res => {
        if (res.code === 200) {
          this.$toast('上传成功')
          this.imgUrl = res.msg
        } else {
          this.$toast('上传失败')
          this.imgUrl = null
          this.fileList = []
        }
      }).finally(() => {
        Toast.clear()
      })
    },
    onConfirm(value) {
      this.showPicker = false
      this.feedbackValue = value
      console.log('indexOf', this.columns.indexOf(value))
      this.feedbackType = this.columns.indexOf(value) + 1
    },
    submit () {
      if (!this.content) {
        this.$toast('请输入投诉内容')
        return
      }
      if (!this.contact) {
        this.$toast('请输入联系人')
        return
      }
      let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!phone.test(this.mobile)) {
        this.$toast('请输入正确的手机号')
        return
      }
      let params = {
        content: this.content,
        contact: this.contact,
        mobile: this.mobile,
        feedbackType: this.feedbackType,
        imgUrl: this.imgUrl
      }
      Toast.loading({
        message: '加载中...',
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      postWebpageFeedbackSubmit(params)
        .then(res => {
          if (res.code === 200) {
            this.$toast('已收到您的投诉，感谢您对平台环境做出的贡献。')
            this.mobile = null
            this.contact = null
            this.feedbackValue = null
            this.feedbackType = null
            this.fileList = []
            this.content = null
            this.imgUrl = null
            setTimeout(() => {
              // this.$router.replace({
              //   path: `/homePage/${this.$store.state.userId}`
              // })
              this.$router.go(-1)
            }, 1000);
          } else {
            this.$toast(res.message)
          }
        })
        .finally(() => {
          Toast.clear()
        })
    }
  }
}
</script>

<style lang="less" scoped>
  .report{
    // background: #191d3d;
    min-height: 100%;
    .van-radio-group {
      margin: 20px 0;
      .van-radio {
        margin: 10px 20px;
      }
    }
    .bigTitle {
      margin: 20px;
      font-weight: bold;
      font-size: 16px;
      text-align: left;
    }
    .title {
      margin: 20px;
      font-weight: 500;
      font-size: 15px;
      text-align: left;
      // color: #fff;
    }
    .text {
      width: 90%;
      margin: 0 auto;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
    }
    .report_btn_wrapper {
      display: flex;
      margin-top: 50px;
      .report_btn {
        width: 95%;
        margin: 0 auto;
        font-weight: 500;
        font-size: 18px;
        border-radius: 10px;
      }
    }
  }
  /ddep/ .van-radio__label {
    color: #fff;
  }
</style>
